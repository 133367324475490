import { deleteAchievement, createAchievement } from "../api";
import { useState } from "react";
import MUIDataTable from "mui-datatables";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function Achievements({ achievements, certificates, fields, handleGetAchievements }) {
  const [activeFields, setFields] = useState([])
  const [requiredFields, setRequiredFields] = useState([])
  const [displayName, setDisplayName] = useState("");
  const [certificateId, setCertificateId] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);

  const handleDeleteAchievement = async (rowsDeleted, data) => {
    for (const i in rowsDeleted.data) {
      try {
        let idx = rowsDeleted.data[i].dataIndex;
        await deleteAchievement(achievements[idx].id);
      } catch (e) {
        console.log(e);
        return false;
      }
    }
  };

  const handleCreateAchievement = async () => {
    await createAchievement({
      displayName,
      fields: [...requiredFields, ...activeFields.map(f => f.id)].filter((id, i, ar) => ar.indexOf(id) === i),
      certificateId
    });
    handleAddClose();
    handleGetAchievements()
  };

  const columns = [
    {
      name: "accountId",
      label: "Account",
    },
    {
      name: "displayName",
      label: "Name",
    },
    {
      name: "fields",
      label: "Fields",
      options: {
        customBodyRenderLite: (idx) => {
          let value = certificates[idx].fields;
          return (
            <div>
              {value.map((f, i, ar) => {

                const field = fields.find(fi => fi.id === f)
                if(!field) return <span key={f}>{f} </span>
                return <span key={f}>{field.displayName}{i + 1 < ar.length ? ',' : ''} </span>
        })}
            </div>
          );
        },
      },
    },
    {
      name: "id",
      label: "Id",
    },
    {
      name: "certificateId",
      label: "certificateId",
    },
  ];

  
  const options = {
    filterType: "checkbox",
    onRowsDelete: handleDeleteAchievement,
  };

  const handleAddOpen = () => setShowAddModal(true);
  const handleAddClose = () => setShowAddModal(false);

  const handleFieldChange = (event) => {
    if(event.target.checked) {
      const addField = fields.find(f => f.id === event.target.name)
      setFields([...activeFields, addField])
      return
    }
    setFields(activeFields.filter(af => af.id !== event.target.name))
  };

  const handleCertificateSelect = (e) => {
    setCertificateId(e.target.value);
    const cert = certificates.find((c) => e.target.value === c.id);
    setRequiredFields([...cert.fields])
  };

  const isFieldDisabled = (field) => {
    return requiredFields.includes(field.id);
  };

  return (
    <Box sx={{ maxWidth: "1200px", m: "auto" }}>
      <Box sx={{ mb: 2, display: "flex", justifyContent: "end" }}>
        <Button onClick={handleAddOpen} variant="contained" color="primary">
          Add Achievement
        </Button>
      </Box>
      <MUIDataTable
        title={"Achievements"}
        data={achievements}
        columns={columns}
        options={options}
      />

      <Modal open={showAddModal} onClose={handleAddClose}>
        <Box sx={style}>
          <TextField
            id="display Name"
            label="Name"
            value={displayName}
            sx={{ width: "100%" }}
            onChange={(e) => setDisplayName(e.target.value)}
          />
          <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
            <FormLabel component="legend">Select Fields</FormLabel>
            <FormGroup>
              {fields.map((field) => {
                const disabled = isFieldDisabled(field);
                const checked = disabled || !!(activeFields.find(af => af.id === field.id))
                return (
                  <FormControlLabel
                    key={field.id}
                    control={
                      <Checkbox
                      checked={checked}
                        onChange={handleFieldChange}
                        disabled={disabled}
                        name={field.id}
                      />
                    }
                    label={field.displayName}
                  />
                );
              })}
            </FormGroup>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="certificate-select">Certificate</InputLabel>
            <Select
              value={certificateId}
              label="Certificate"
              onChange={handleCertificateSelect}
            >
              {certificates.map((cert) => (
                <MenuItem key={cert.id} value={cert.id}>
                  {cert.displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            onClick={handleCreateAchievement}
            sx={{ mt: 2 }}
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </Box>
      </Modal>
    </Box>
  );
}

export default Achievements;
