import { createField, deleteField } from "../api";
import { useState } from "react";
import MUIDataTable from "mui-datatables";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function Fields({ fields, handleGetFields }) {
  const [showAddModal, setShowAddModal] = useState(false);
  const [displayName, setDisplayName] = useState('')
  const [description, setDescription] = useState('')
  const [type, setType] = useState('')
  const [dynamic, setDynamic] = useState(false)
  const handleCreateField = async () => {
    const resp = await createField({
      displayName, description, type, dynamic
    });
    setShowAddModal(false);
    handleGetFields()
  };

  const handleDeleteField = async (rowsDeleted, data) => {
    for (const i in rowsDeleted.data) {
      try {
        let idx = rowsDeleted.data[i].dataIndex;
        await deleteField(fields[idx].id);
      } catch (e) {
        console.log(e);
        return false;
      }
    }
  };

  const columns = [
    {
      name: "accountId",
      label: "Account",
    },
    {
      name: "id",
      label: "Id",
    },
    {
      name: "displayName",
      label: "Display Name",
    },
    {
      name: "description",
      label: "Description",
    },
    {
      name: "type",
      label: "Type",
    },
    {
      name: "dynamic",
      label: "Value is Dynamic",
    },
  ];

  const options = {
    filterType: "checkbox",
    onRowsDelete: handleDeleteField,
  };

  const handleAddOpen = () => setShowAddModal(true);
  const handleAddClose = () => setShowAddModal(false);

  return (
    <Box className="Fields">
      <Box sx={{ mb: 2, display: "flex", justifyContent: "end" }}>
        <Button onClick={handleAddOpen} variant="contained" color="primary">
          Add Field
        </Button>
      </Box>
      <MUIDataTable
        title={"Fields"}
        data={fields.map(f => {f.dynamic = (f.dynamic ?? false).toString(); return f})}
        columns={columns}
        options={options}
      />

      <Modal open={showAddModal} onClose={handleAddClose}>
        <Box sx={style}>
          <TextField
            id="display-name"
            label="Display Name"
            value={displayName}
            sx={{ width: "100%" }}
            onChange={(e) => setDisplayName(e.target.value)}
          />
          <TextField
            id="description"
            label="Description"
            value={description}
            sx={{ width: "100%" }}
            onChange={(e) => setDescription(e.target.value)}
          />
          <TextField
            id="type"
            label="Type"
            value={type || 'text'}
            sx={{ width: "100%" }}
            onChange={(e) => setType(e.target.value)}
          />
          <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!dynamic}
                      onChange={e => setDynamic(!dynamic)}
                      name={"dynamic"}
                      
                    />
                  }
                  label={"Value is Dynamic"}
                />
<hr />
          <Button
            onClick={handleCreateField}
            sx={{ mt: 2 }}
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </Box>
      </Modal>
    </Box>
  );
}

export default Fields;
