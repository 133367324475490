import axios from "axios";
import { validate } from 'uuid'

const getAccountId = () => {
  console.log('finding accountID')
  const defaultId = '123'
  const path = window.location.pathname
  console.log('path accountID', path)
  if(!path) return defaultId
  const [first, second, ...rest] = path.split('/')
  console.log('prevalidated accountID', first, second)
  if(!first && !second) return defaultId
  console.log('validating')
  if(validate(first)) return first
  if(validate(second)) return second
  return defaultId
}

const accountId = getAccountId()

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/v1/${accountId}`,
  headers: {
    "Content-Type": "application/json",
  },
});

//Achievements Endpoints
export const createAchievement = async (acheivementData) => {
  const resp = await instance.post(`/achievements/`, acheivementData);
  return resp.data;
};

export const getAchievements = async () => {
  const resp = await instance.get(`/achievements/`);
  return resp.data;
};
export const getAchievement = async (id) => {
  const resp = await instance.get(`/achievements/${id}`);
  return resp.data;
};

export const deleteAchievement = async (id) => {
  const resp = await instance.delete(`/achievements/${id}`);
  return resp.data;
};

//Certificates Endpoints
export const createCertificate = async (certificateData) => {
  const resp = await instance.post(`/certificates/`, certificateData);
  return resp.data;
};

export const getCertificates = async () => {
  const resp = await instance.get(`/certificates/`);
  return resp.data;
};
export const getCertificate = async (id) => {
  const resp = await instance.get(`/certificates/${id}`);
  return resp.data;
};

export const deleteCertificate = async (id) => {
  const resp = await instance.delete(`/certificates/${id}`);
  return resp.data;
};

//Awards Endpoints
export const createAward = async (awardData) => {
  const resp = await instance.post(`/awards/`, awardData);
  return resp.data;
};

export const getAwards = async () => {
  const resp = await instance.get(`/awards/`);
  return resp.data;
};
export const getAward = async (id) => {
  const resp = await instance.get(`/awards/${id}`);
  return resp.data;
};

export const deleteAward = async (id) => {
  const resp = await instance.delete(`/awards/${id}`);
  return resp.data;
};


//Fields Endpoints
export const createField = async (fieldData) => {
  console.log(fieldData)
  const resp = await instance.post(`/fields/`, fieldData);
  return resp.data;
};

export const getFields = async () => {
  const resp = await instance.get(`/fields/`);
  return resp.data;
};
export const getField = async (id) => {
  const resp = await instance.get(`/fields/${id}`);
  return resp.data;
};

export const deleteField = async (id) => {
  const resp = await instance.delete(`/fields/${id}`);
  return resp.data;
};
