import "./App.css";
import { useEffect, useState } from "react";
import Certificates from "./components/Certificates";
import Achievements from "./components/Achievements";
import Awards from "./components/Awards";
import Fields from "./components/Fields";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { getCertificates, getAchievements, getAwards, getFields } from "./api";

function TabPanel(props) {
  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function App() {
  const [tab, setTab] = useState(0);
  const [certificates, setCertificates] = useState([]);
  const [achievements, setAchievements] = useState([]);
  const [awards, setAwards] = useState([]);
  const [fields, setFields] = useState([]);

  useEffect(() => {
    handleGetCertificates();
    handleGetAchievements();
    handleGetAwards();
    handleGetFields();
  }, []);

  const handleGetCertificates = async () => {
    const resp = await getCertificates();
    setCertificates(resp);
  };

  const handleGetAchievements = async () => {
    const resp = await getAchievements();
    setAchievements(resp);
  };

  const handleGetAwards = async () => {
    const resp = await getAwards();
    setAwards(resp);
  };

  const handleGetFields = async () => {
    const resp = await getFields();
    setFields(resp);
  };

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <div className="App">
      <Box sx={{ borderBottom: 1, borderColor: "divider", m: "auto" }}>
        <Typography variant="h2" color="initial">
          Achievements
        </Typography>
        <Container maxWidth="sm">
          <Tabs value={tab} onChange={handleChange} aria-label="">
            <Tab label="Certificates" />
            <Tab label="Achievements" />
            <Tab label="Awards" />
            <Tab label="Fields" />
          </Tabs>
        </Container>
      </Box>
      <TabPanel value={tab} index={0}>
        <Certificates certificates={certificates} fields={fields} handleGetCertificates={handleGetCertificates} />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <Achievements achievements={achievements} certificates={certificates} fields={fields} handleGetAchievements={handleGetAchievements} />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <Awards awards={awards} achievements={achievements} fields={fields} handleGetAwards={handleGetAwards} />
      </TabPanel>
      <TabPanel value={tab} index={3}>
        <Fields fields={fields} handleGetFields={handleGetFields} />
      </TabPanel>
    </div>
  );
}

export default App;
